<template>
  <BaseList
    :list-query="hundreds"
    route="hundred"
    locale-section="pages.hundreds"
  >
    <template v-slot:extra="{ element: { province } }">
      ( {{ province.province }}/{{ province.country.country }} )
    </template>
  </BaseList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HundredList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      hundreds: gql`
        query hundreds {
          list: hundreds {
            id
            name: hundred
            hundred
            division {
              id
            }
            province {
              province
              country {
                country
              }
            }
          }
        }
      `
    };
  }
};
</script>
